@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
@font-face {
    font-family:'Mulish';
    src: local('Mulish'), url(./fonts/mulish.ttf) format('ttf');
}
}

.atoms_title {
    font-family: 'Press Start 2P', cursive;
}
